// react
import Proptypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
// redux
import { useDispatch } from 'react-redux'
import { updateUserWithCoach } from '../../dashboard/graphql/mutations'
import { setUserIdAction, getUserByIdAction } from '../../redux/actions/user.actions'

// amplify
import { API, graphqlOperation } from 'aws-amplify'
import Amplify from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth'
import { AmplifyAuthenticator, AmplifySignUp } from '@aws-amplify/ui-react'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import awsconfig from '../../aws-exports'

// Components
import { toast, ToastContainer } from 'react-toastify'

// amplify config
Amplify.configure(awsconfig)
Auth.configure(awsconfig)
// * container
/**
 * AuthStateApp container (Amplify)
 * @component
 * @param {undefined} children
 */
const AuthStateApp = ({ children }) => {
  // ? hooks
  const dispatch = useDispatch()
  const [authState, setAuthState] = useState()
  const [user, setUser] = useState(null)
  // const [coach, setCoach] = useState(null)

  const updateUserCoach = async (userId, coachId) => {
    const response = await API.graphql(graphqlOperation(updateUserWithCoach(userId, coachId)))
    return response
  }

  useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState)
      invinfo && authData && updateUserCoach(authData.username, id)
      setUser(authData)
    })
  }, [])

  useEffect(async () => {
    // setCoach('test')
    if (user !== null && authState === 'signedin') {
      const { username } = user
      dispatch(setUserIdAction(username))
      dispatch(getUserByIdAction(username))
    }
  }, [user])

  useEffect(() => {
    onAuthUIStateChange((nextAuthState) => {
      if (nextAuthState === AuthState.ConfirmSignUp) {
        toast.clearWaitingQueue()
        toast.dark('Confirmation code sent to your phone')
      }
    })
  }, [authState])

  const q = new URLSearchParams(useLocation().search)

  const invinfo = q.get('invcode')
  const [id, coachFirst, coachLast] = window.atob(invinfo).split(';')

  return authState === AuthState.SignedIn && user
    ? <div className="App">{children}</div>
    : <>
        <ToastContainer
          limit={1}
          position="top-center"
          autoClose={false}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <AmplifyAuthenticator initialAuthState={AuthState.SignUp}>
          <AmplifySignUp
            slot="sign-up"
            headerText={invinfo ? `Joining ${coachFirst} ${coachLast}'s team` : 'Create Account ' }
            usernameAlias="email"
            formFields={[
              {
                type: 'email',
                label: 'Email',
                placeholder: 'jondoe@gmail.com',
                required: true
              },
              {
                type: 'password',
                label: 'Password',
                placeholder: '',
                required: true
              },
              {
                type: 'phone_number',
                label: 'Phone #',
                placeholder: '(415) 348-9900',
                required: false
              },
              {
                type: 'name',
                label: 'First Name',
                placeholder: 'John',
                required: false
              },
              {
                type: 'family_name',
                label: 'Last Name',
                placeholder: 'Doe',
                required: false
              },
              // {
              //   type: 'username',
              //   label: 'user name',
              //   placeholder: 'custom Phone placeholder',
              //   required: false
              // },
              {
                type: 'gender',
                label: 'Gender',
                placeholder: 'Gender',
                required: false
              },
              {
                type: 'birthdate',
                label: 'Birthdate',
                placeholder: '06/17/1990',
                required: false
              }
              // {
              //   type: 'userCoachId',
              //   label: '',
              //   value: id,
              //   required: false,
              //   name: 'CoachID',
              //   fieldId: 'tester',
              //   inputProps: { style: { display: 'none' } },
              //   disabled: true
              // }

            ]}
          />
        </AmplifyAuthenticator>
      </>
}

// prop-types
AuthStateApp.propTypes = {
  /** children */
  children: Proptypes.element.isRequired
}

export default AuthStateApp
